import { Auth } from 'aws-amplify';
import Constants from '@/constants';

import {
    ChartJsDataset,
    DailyRegistrationData,
    MonthlyRegistrationData,
    QueryPortalQuery,
    RealmType,
    YearlyRegistrationCounts,
    YearlyRegistrationData
} from '@/store/types';

const api = 'https://datamine-api.rusticisoftware.com';
//const api = 'http://localhost:5000';

// datamine.setFresh(true) will add `?fresh=true` to each request, bypassing
//      Flask cache. Used for Refresh Data button in each tab.
let fresh = false;
const setFresh = (newVal: boolean) => (fresh = newVal);

const getJwtToken = async () =>
    (await Auth.currentAuthenticatedUser()).signInUserSession
        .getAccessToken()
        .getJwtToken();

const datamineReq = async (URL: string, abortSignal?: AbortSignal) =>
    fetch(fresh ? `${URL}?fresh=true` : URL, {
        headers: { 'Authorization': await getJwtToken() },
        signal: abortSignal
    }).then((res) => {
        return res.ok ? res.json() : res.statusText;
    });

// returns ['YYYY'] array for last three years
const lastThreeYears = (): string[] => {
    const yr = new Date().getFullYear();
    return [(yr - 2).toString(), (yr - 1).toString(), yr.toString()];
};

// returns ['MM/DD/YYYY'] array for each day of the year so far
const daysUntilNow = (): string[] => {
    const today = new Date();
    const yearStart = new Date(today.getFullYear(), 0, 1);
    const dates: string[] = [];

    for (
        let date = new Date(yearStart);
        date <= today;
        date.setDate(date.getDate() + 1)
    ) {
        dates.push(`${date.getMonth() + 1}/${date.getDate()}`);
    }

    return dates;
};

// returns ['January', 'February', 'March' ...]
const allMonths = (): string[] => {
    return Array.from({ length: 12 }, (_, i) =>
        new Date(new Date().getFullYear(), i).toLocaleString('en-US', {
            month: 'long'
        })
    );
};

const exports = {
    setFresh,

    // build chart/table data structures from API response
    getMonthlyRegChartFromMonthly: (
        monthlyData: { count: number; month: number; year: number }[]
    ) => {
        const chartData = {
            labels: allMonths(),
            datasets: [] as ChartJsDataset[]
        };

        if (monthlyData.length === 0) return chartData;

        const yearData: YearlyRegistrationData = {};

        const years = lastThreeYears();

        years.forEach((yr) => {
            yearData[yr] = [];
            allMonths().forEach((mnth) => {
                const monthData: MonthlyRegistrationData = {};
                yearData[yr].push(monthData);
            });
        });

        monthlyData.forEach((itm) => {
            const yr = itm.year.toString();
            const mnth = itm.month - 1;

            if (years.includes(yr)) {
                const monthData = yearData[yr][mnth];
                monthData[mnth.toString()] =
                    (monthData[mnth.toString()] || 0) + Number(itm.count);
            }
        });

        Object.keys(yearData).forEach((k, i) => {
            yearData[k].sort();

            const data = yearData[k].map((mnth) => Object.values(mnth)[0]);

            // remove years with no data from dataset
            if (data.every((value) => typeof value === 'undefined')) {
                chartData.labels = chartData.labels.filter((l) => l !== k);
                return;
            }

            chartData.datasets.push({
                label: k,
                data: data,
                backgroundColor: Constants.chartColors[i]
            });
        });

        return chartData;
    },
    getMonthlyRegChartFromDaily: (dailyData: DailyRegistrationData[]) => {
        const chartData = {
            labels: allMonths(),
            datasets: [] as ChartJsDataset[]
        };

        const yearData: YearlyRegistrationData = {};

        const years = lastThreeYears();

        years.forEach((yr) => {
            yearData[yr] = [];
            allMonths().forEach((mnth) => {
                const monthData: MonthlyRegistrationData = {};
                yearData[yr].push(monthData);
            });
        });

        dailyData.forEach((itm: DailyRegistrationData) => {
            const dt = new Date(itm.regdate);
            const yr = dt.getFullYear().toString();
            const mnth = dt.getMonth();

            if (years.includes(yr)) {
                const monthData = yearData[yr][mnth];
                monthData[mnth.toString()] =
                    (monthData[mnth.toString()] || 0) + Number(itm.regcount);
            }
        });

        Object.keys(yearData).forEach((k, i) => {
            yearData[k].sort();

            const data = yearData[k].map((mnth) => Object.values(mnth)[0]);

            chartData.datasets.push({
                label: k,
                data: data,
                backgroundColor: Constants.chartColors[i]
            });
        });

        return chartData;
    },
    getDailyAvgRegChart: (dailyData: DailyRegistrationData[]) => {
        const chartData = {
            labels: daysUntilNow(),
            datasets: [] as ChartJsDataset[]
        };

        const years = lastThreeYears();
        const counts: YearlyRegistrationCounts = {};

        years.map((yr) => (counts[yr] = []));

        dailyData.forEach((itm: DailyRegistrationData) => {
            const dt = new Date(itm.regdate);
            const dayMonth = `${dt.getMonth() + 1}/${dt.getDate()}`;
            const yr = dt.getFullYear().toString();

            if (!chartData.labels.includes(dayMonth) || !years.includes(yr)) {
                return;
            }

            counts[yr].push(itm);
        });

        years.forEach((k, i) => {
            if (Number(k) % 4 !== 0) {
                counts[k].push({ regcount: '0', regdate: '2/28/' + k });
            }
            counts[k].sort((a, b) => {
                return new Date(a.regdate) > new Date(b.regdate) ? 1 : -1;
            });

            const ds: ChartJsDataset = {
                label: k,
                data: counts[k].map((itm) => Number(itm.regcount)),
                backgroundColor: Constants.chartColors[i]
            };

            chartData.datasets.push(ds);
        });

        return chartData;
    },
    getMonthlyRegTableFromDaily: (dailyData: DailyRegistrationData[]) => {
        const yearData: YearlyRegistrationData = {};

        const thisYear = new Date().getFullYear();
        const years: string[] = [];
        years.push(thisYear.toString());
        years.push((thisYear - 1).toString());
        years.push((thisYear - 2).toString());

        years.forEach((yr) => {
            yearData[yr] = [];
            allMonths().forEach((mnth) => {
                const monthData: MonthlyRegistrationData = {};
                yearData[yr].push(monthData);
            });
        });

        dailyData.forEach((itm: DailyRegistrationData) => {
            const dt = new Date(itm.regdate);
            const yr = dt.getFullYear().toString();
            const mnth = dt.getMonth();

            if (years.includes(yr)) {
                const monthData = yearData[yr][mnth];
                monthData[mnth.toString()] =
                    (monthData[mnth.toString()] || 0) + Number(itm.regcount);
            }
        });

        return yearData;
    },
    getMonthlyRegTableFromMonthly: (
        monthlyData: { count: number; month: number; year: number }[]
    ) => {
        const yearData: YearlyRegistrationData = {};

        if (monthlyData.length === 0) return yearData;

        const thisYear = new Date().getFullYear();
        const years: string[] = [];
        years.push(thisYear.toString());
        years.push((thisYear - 1).toString());
        years.push((thisYear - 2).toString());

        years.forEach((yr) => {
            yearData[yr] = [];
            allMonths().forEach((mnth) => {
                const monthData: MonthlyRegistrationData = {};
                yearData[yr].push(monthData);
            });
        });

        monthlyData.forEach((itm) => {
            const yr = itm.year.toString();
            const mnth = itm.month - 1;

            if (years.includes(yr)) {
                const monthData = yearData[yr][mnth];
                monthData[mnth.toString()] =
                    (monthData[mnth.toString()] || 0) + Number(itm.count);
            }
        });

        // remove years with no data from dataset
        Object.keys(yearData).forEach((k) => {
            if (Object.keys(yearData[k][0]).length === 0) {
                delete yearData[k];
            }
        });

        return yearData;
    },

    // aggregate
    getRegistrationsByMonth: () =>
        datamineReq(`${api}/registration_counts`) as Promise<
            DailyRegistrationData[]
        >,

    // accounts
    getAccountActivityCurrentMonth: () =>
        datamineReq(`${api}/accounts/current_month_activity`),
    getAccountActivityLastMonth: () =>
        datamineReq(`${api}/accounts/previous_month_activity`),

    // realms
    searchRealms: (term: string, page: number, abortSignal?: AbortSignal, pageSize?: number) =>
        datamineReq(`${api}/realms/search?term=${term}&page=${page}${pageSize ? `&pageSize=${pageSize}` : ''}`, abortSignal),
    getRealmsByType: (realmType: RealmType) =>
        datamineReq(`${api}/realms/${realmType}`),
    getRealmDetails: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/details`),
    getRealmRegistrationCounts: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/registration_counts`),
    getRealmOwners: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/owners`),
    getRealmApplications: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/applications`),
    getRealmAccountEvents: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/account_events`),
    getRealmNewLearnersYOY: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/yearly_new_learners`),
    getRealmUniqueLearnersPastYear: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/unique_learners_past_year`),
    getRealmRegistrationsPastYear: (realmId: string) =>
        datamineReq(`${api}/realm/${realmId}/registrations_past_year`),

    // query portal (realms) -- date format is YYYY-MM-DD (ISO8601) strings
    portalRegistrationCounts: (realmId: string, startDate: string, endDate: string) =>
        datamineReq(`${api}/realm/${realmId}/registration_counts?fresh=true&startDate=${startDate}&endDate=${endDate}`),
    portalAverageRegistrationsPerLearner: (realmId: string, startDate: string, endDate: string) =>
        datamineReq(`${api}/realm/${realmId}/average_registrations_per_learner?fresh=true&startDate=${startDate}&endDate=${endDate}`),
    portalTotalRegistrations: (realmId: string, startDate: string, endDate: string) =>
        datamineReq(`${api}/realm/${realmId}/total_registrations?fresh=true&startDate=${startDate}&endDate=${endDate}`),
    portalTotalLearners: (realmId: string, startDate: string, endDate: string) =>
        datamineReq(`${api}/realm/${realmId}/total_learners?fresh=true&startDate=${startDate}&endDate=${endDate}`),
    portalLearnerCounts: (realmId: string, startDate: string, endDate: string) =>
        datamineReq(`${api}/realm/${realmId}/learner_counts?fresh=true&startDate=${startDate}&endDate=${endDate}`),
};

// used in QueryPortalTab.vue
const portalQueries: QueryPortalQuery[] = [
    {
        name: 'Average Registrations per Learner',
        func: exports.portalAverageRegistrationsPerLearner,
        description: `The average number of registrations created per learner in a given timeframe.
        Useful for Account Managers to get a read on which pricing model to use.`
    },
    {
        name: 'Registrations by Month',
        func: exports.portalRegistrationCounts,
        description:
            'The number of registrations created in a given timeframe, broken down by month.'
    },
    {
        name: 'Total Registration Count',
        func: exports.portalTotalRegistrations,
        description:
            'The total number of registrations created in a given timeframe.'
    },
    // this query can take 20+ minutes. add a warning
    {
        name: 'Learners by Month',
        func: exports.portalLearnerCounts,
        description:
            'The number of learners who created registrations in a given timeframe, broken down by month.'
    },
    {
        name: 'Total Unique Learners',
        func: exports.portalTotalLearners,
        description:
            'The total number of unique learners who created registrations in a given timeframe.'
    }
];

export default { ...exports, portalQueries };

    import Vue from 'vue';
    import Constants from '@/constants';
    import SettingsTable from '@/components/Cloud/SettingsTable.vue';
    import EnvironmentCard from '@/components/Cloud/EnvironmentCard.vue';
    import SnackbarAlert from '@/components/common/SnackbarAlert.vue';
    import {
        CloudEnvironment,
        SnackbarData,
        SnackbarEvent
    } from '@/store/types';

    export default Vue.extend({
        name: 'CloudDashboard',
        data() {
            return {
                envs: ['Stagingx'] as CloudEnvironment[],
                snackbar: {} as SnackbarData
            };
        },
        computed: {
            allEnvs(): CloudEnvironment[] {
                return Constants.cloudEnvironments.reverse() as CloudEnvironment[];
            },
            // Don't display until correct sort order is verified
            //    (testers reported seeing envs out of order)
            sortComplete(): boolean {
                return (
                    this.allEnvs.length === 3 && this.allEnvs[0] == 'Stagingx'
                );
            }
        },
        methods: {
            toggleActive(env: CloudEnvironment) {
                // deselect environment if it's not the last active one
                if (this.envs.length > 1 && this.isActive(env)) {
                    this.envs = this.envs.filter((e) => e !== env);
                }
                // select environment if not already selected
                else if (!this.isActive(env)) {
                    this.envs = [...this.envs, env];
                }
            },
            isActive(env: CloudEnvironment) {
                return this.envs.includes(env);
            },
            showAlert(evt: SnackbarEvent, status: string) {
                let [message, icon] = ['', ''];
                if (status == 'error') evt = 'error';

                switch (evt) {
                    case 'unlocked':
                        [message, icon] = [
                            'Grid cells are now interactive',
                            'mdi-pencil'
                        ];
                        break;
                    case 'saved':
                        [message, icon] = [
                            'Changes successfully saved',
                            'mdi-content-save'
                        ];
                        break;
                    case 'success':
                        [message, icon] = ['Success!', 'mdi-check'];
                        break;
                    case 'error':
                        [message, icon] = ['Error', 'mdi-alert-circle-outline'];
                        break;
                    default:
                        break;
                }

                this.snackbar = {
                    event: evt,
                    show: true,
                    message: message,
                    timeout: 3500,
                    icon: icon
                };
            }
        },
        components: {
            SettingsTable,
            EnvironmentCard,
            SnackbarAlert
        }
    });


    import Vue from 'vue';
    import { PropType } from 'vue';
    import { SnackbarData } from '@/store/types';

    export default Vue.extend({
        name: 'SnackbarAlert',
        props: {
            data: {
                type: Object as PropType<SnackbarData>,
                required: true
            }
        },
        computed: {
            showSnackbar: {
                get: function (): boolean {
                    return this.data.show;
                },
                set: function (): void {
                    this.$emit('closed');
                }
            },
            getColor(): string {
                const ev = this.data.event;

                if (ev == 'success' || ev == 'saved') return 'success';
                else if (ev == 'error') return 'error';
                return 'info';
            }
        }
    });
